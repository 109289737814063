@import "nlib/config";

.container {
  width: 100%;
  padding-right: $contentDefaultIndent * 2;
  padding-left: $contentDefaultIndent * 2;
}
.row {
  display: flex;
}
.col {
  flex: 1;
}
.negativeColor,
.negativeText {
  color: $uiNegativeColor;
}
.highlightText {
  color: $uiHighlightColor !important;
}
.negativeText {
  color: $uiNegativeColor !important;
}
.positiveText {
  color: $uiPositiveColor !important;
}
.warningText {
  color: $uiWarningColor !important;
}
.specialText {
  color: $uiSpecialColor !important;
}
.neutralText {
  color: $uiNeutralColor !important;
}
.whiteText {
  color: $uiWhiteColor !important;
}
.lightText {
  color: $uiWhiteColor !important;
}
.mediumDarkText {
  color: $uiMediumDarkColor !important;
}
.darkText {
  color: $uiDarkColor !important;
}
.blackText {
  color: $uiBlackColor !important;
}
.textCenter {
  text-align: center;
}
.flexCenter {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}
.wait {
  animation: rotate 2s linear infinite;
  color: $uiNeutralColor;
}
.coloredNumber {
  color: $uiPositiveColor !important;
  &[data-negative] {
    color: $uiNegativeColor !important;
  }
}
%mobileContainer {
  width: 100%;
  max-width: $maxMobileContainerWidth + $contentDefaultIndent * 4;
  margin-right: auto;
  margin-left: auto;
  padding-right: $contentDefaultIndent * 2;
  padding-left: $contentDefaultIndent * 2;
}
.mobileContainer {
  @extend %mobileContainer;
}
.tableRow {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: $contentDefaultIndent;
    bottom: $contentDefaultIndent;
    left: 0;
    width: 3px;
    border-radius: 3px;
  }
  &.highlightRow {
    &::before {
      background-color: $uiPrimaryColor;
    }
  }
  &.warningRow {
    &::before {
      background-color: $uiWarningColor;
    }
  }
  &.positiveRow {
    &::before {
      background-color: $uiPositiveColor;
    }
  }
  &.negativeRow {
    &::before {
      background-color: $uiNegativeColor;
    }
  }
  &.selectedRow {
    background-color: mix($uiNeutralColor, $uiWhiteColor, 15%) !important;
  }
}
.noDataContent {
  margin: auto;
  font-size: 1.4em;
  text-align: center;
  > div:first-child {
    max-width: 12em;
    margin: auto;
    > div:first-child {
      svg {
        margin-bottom: $contentDefaultIndent;
        opacity: 0.2;
        font-size: 6rem;
      }
    }
    button,
    ~ div {
      margin-top: $contentDefaultIndent * 2;
    }
  }
}
.card {
  padding: $contentDefaultIndent * 2 $contentDefaultIndent * 2.5;
  border-radius: $contentDefaultIndent;
  background-color: $uiWhiteColor;
}
